<template>
  <div class="p-5 pt-0">
    <header class="d-flex justify-content-between pb-5">
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm-dark.png" alt height="22" />
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-dark.png" alt height="20" />
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm-light.png" alt height="20" />
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-light.png" alt height="40" />
          </span>
        </a>
      </div>

      <button class="btn">
        <router-link to="/login">Login</router-link>
      </button>
    </header>

    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et luctus
      metus. Duis pretium vel metus eu posuere. Fusce semper, arcu non rhoncus
      cursus, dolor velit scelerisque arcu, vel elementum arcu metus eget dui.
      Nulla facilisis id tortor a pharetra. Etiam eget sem dapibus, tincidunt
      nibh quis, eleifend ligula. Sed hendrerit elit ac dapibus hendrerit.
      Aliquam dolor orci, tincidunt non euismod in, pulvinar nec ex. Fusce dui
      neque, vehicula a pharetra at, vulputate eu felis. Integer enim sem,
      facilisis sit amet lectus sed, tincidunt tristique odio. Cras eu lectus
      non diam pretium fermentum. Proin non volutpat nibh, non commodo lectus.
      Fusce felis lacus, pulvinar in diam et, malesuada bibendum ante. Curabitur
      sit amet lacus a tortor facilisis condimentum eget non sem. Duis a
      elementum nibh. Quisque fringilla augue non tortor porttitor, id luctus
      enim sodales. Suspendisse molestie ipsum scelerisque mi efficitur
      dignissim. Aenean sed facilisis turpis. Aenean quis erat nec turpis
      venenatis luctus sed et lacus. Morbi ante nunc, lacinia at imperdiet eu,
      tempor sed nibh. Praesent lorem lectus, tempus vel ipsum ut, efficitur
      tincidunt nisi. Pellentesque non ipsum vel nibh pulvinar facilisis.
      Maecenas vitae turpis quis nibh cursus finibus sit amet id sapien.
      Pellentesque interdum velit nec enim rhoncus ultricies. Suspendisse
      sodales turpis vitae mollis ultricies. Fusce malesuada arcu leo, sed
      egestas sem tempor nec. Ut vel tempor nunc, a ultrices dui. Nam convallis
      auctor ante, sed placerat lectus scelerisque nec. Praesent aliquet ex
      libero, nec vestibulum felis luctus a. Nullam ac tortor ullamcorper,
      vestibulum massa at, pretium lacus. Pellentesque commodo molestie nisl,
      finibus volutpat purus aliquam dapibus. Nunc mauris elit, convallis sit
      amet hendrerit non, aliquam vel ex. Nam et aliquet nisi, in dapibus
      tortor. Nunc gravida gravida metus. Aliquam a tellus molestie magna varius
      convallis a in lacus. Praesent id commodo dui. Etiam sodales velit vel
      nisl volutpat, pretium dignissim est condimentum. Pellentesque nibh lorem,
      tincidunt sed vulputate et, semper eu odio. Phasellus posuere fermentum
      neque a feugiat. Pellentesque lobortis velit at dignissim molestie. Nunc
      efficitur tellus vel neque ultrices, et posuere quam malesuada. Fusce urna
      libero, vulputate vitae arcu eu, laoreet tempus est. In tempus et dui ac
      maximus. Phasellus tincidunt varius est, et suscipit leo volutpat ut.
      Donec tempor porttitor orci, id porta ex condimentum nec. Sed vitae
      gravida quam. Nulla vestibulum eleifend lacinia. Nullam aliquet turpis eu
      tempor convallis. Phasellus sit amet odio enim.
    </div>
  </div>
</template>

<script>
export default {
  name: "policies-privacity",
};
</script>

<style scoped></style>
